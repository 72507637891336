<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Categoría</template>
    <template #body>
      <!-- <FormText
        v-if="item && item.id"
        label="Nombre"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
        :disabled="true"
      /> -->
      <FormText
        label="Nombre"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
      />
      <FormText
        label="Direccion"
        icon="fa-solid fa-home"
        v-model="form.domicilio"
      />
      <FormText
        label="Telefono"
        icon="fa-solid fa-phone"
        v-model="form.telefono"
      />
      <!-- <FormAutoComplete 
        v-else
        label="Nombre de Juez"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
        :data="listaPersonas"
      /> -->
      <FormText
        label="Cargo"
        icon="fa-solid fa-tag"
        v-model="form.cargo"
      />
      <FormText
        label="Posición"
        icon="fa-solid fa-tag"
        v-model="form.pos"
      />
      <div class="imagen-destacada">
        <FormUploader imageType @onChange="onImgUploaded">
          <button class="btn btn-primary">Subir Imagen Destacada</button>
        </FormUploader>
        <div
          class="img-container img-container-md contain d-inline-block"
          :style="`background-image: url('${form.img.url}')`"
          v-if="form.img?.url"
        >
          <div class="img-buttons">
            <button class="btn btn-outline-danger btn-rounded" @click="deleteImg">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
      </div>

    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
// import FormAutoComplete from "../../../components/Form/FormAutoComplete.vue";
import FormUploader from "../../../components/Form/FormUploader.vue";

export default {
  components: {
    FormText,
    // FormAutoComplete,
    FormUploader
},
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form:{},
  }),
  async mounted() {
    if (this.item) {
      this.form = {...this.item};
      this.form.img = {
        url: this.item.img_url
      }
    }
    this.$store.dispatch('getListaCategoriasTorneos')
    this.$store.dispatch('getListaPersonas');
  },
  computed:{
    listaPersonas(){
      if (!this.$store.getters.listaPersonas) return []
      return this.$store.getters.listaPersonas.map(p => `${p.id} - ${p.nombre}`)
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    handleRemoveGaleria(cat){
      this.form.categorias = this.form.categorias.filter(c => c.value != cat.value)
    },
    saveData() {
      if (!this.form.nombre){
        this.$toast.error("Ingrese un Nombre");
      }else{
      const id = this.form.id
      const form = new FormData();
      // const id_persona = this.item ? this.item.id : this.form.nombre?.split('-')[0]?.trim() || null
      form.append('nombre', this.form.nombre)
      form.append('domicilio', this.form?.domicilio || '-')
      form.append('telefono', this.form?.telefono || '-')
      form.append('id_persona',  this.form?.id || Math.floor(Math.random() * 8375))
      if (this.form.cargo) form.append('cargo', this.form.cargo)
      if (this.form.pos) form.append('pos', this.form.pos)
      if (this.form.img?.file) form.append('img', this.form.img.file)
      if (this.form.delete_img) form.append('delete_img', this.form.delete_img)
      if (this.form.id) {
        console.log('updateando personas')
        this.$store
          .dispatch("updateMiembroComite", {
            id,
            form,
          })
          .then(() => {
            this.close();
          });
      } else {
        console.log('creando personas')
        this.$store.dispatch("createMiembroComite", form).then(() => {
          this.close();
        });
      }
      this.$emit('saved')
      }
    },
    onImgUploaded({ file, url }) {
      this.form.img = {
        file,
        url,
      };
    },
    deleteImg() {
      this.form.img = null;
      this.form.delete_img = true;
    },
  },
};
</script>
<style lang="scss" scoped>
  .button_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 28px;
    gap: 5px;
  }
  .close{
    margin-left: 5px;
     &:hover{
      cursor: pointer;
      color: black;
    }
  }
  

</style>
